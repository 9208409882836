import React, { useState, useEffect } from 'react';
import Inputfield from '../../components/Inputfiled';
import HelpSupport from '../../assets/Png/helpsupport.png';
import * as Yup from 'yup'; // Import Yup for validation
import { get, post } from '../../utils/axiosInstance';
import { useFormik } from 'formik';
import Spinner from "../Spinner";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Toast from '../Toast';
import LoadingButton from '../LoadingButton';
import { useSelector } from 'react-redux';
const Maincard = () => {
    const user = useSelector((state) => state.user);
    const [loader, setLoader] = useState(false);
    const [userData, setUserData] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);
    const Token = localStorage.getItem('token');

    const validationSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email format'),
        contact_no: Yup.string(),
        description: Yup.string().required('Description is required'),
    });

    const formik = useFormik({
        initialValues: {
            email: '',
            contact_no: '',
            description: '',
        },
        validationSchema,
        onSubmit: (values) => {
            submitForm(values);
        },
    });

    useEffect(() => {
        setLoader(true);
        getUserData();
    }, []);

    const getUserData = () => {
        setLoader(true);

        get(`/help-and-support`, {}, {}, Token)
            .then((res) => {
                setLoader(false);
                const user = res.response.data.help_and_support;
                setUserData(user);
                formik.setValues({
                    email: user.email || "",
                    contact_no: user.contact_no || "",
                })
            })
            .catch((err) => {
                Toast({ message: err.response.data.error.messages[0], isError: true });
            })
    };

    const submitForm = (values) => {
        setIsProcessing(true);
        let data = {
            "name": `${user.first_name} ${user.last_name}`,
            "email": user.email,
            "description": values.description
        };
        post(`/admin/support`, data, {}, Token)
            .then((res) => {
                setIsProcessing(false);
                Toast({ message: res.response.messages[0], isError: false });
            })
            .catch((err) => {
                setIsProcessing(false);
                Toast({ message: err.response.data.error.messages[0], isError: true });
            });
    };

    if (loader) {
        return (
            <div className="flex justify-center items-center w-full  md:h-[670px] h-[650px]">
                <Spinner className="spinner" isLoading={loader} />
            </div>
        );
    } else {
        return (
            <div className='bg-[#FFFFFF]  rounded-[10px] flex flex-col items-center w-[100%]   md:h-[670px] h-[87vh]'>
                <div className=' border-b-[1px] border-[#EDF0F6] md:py-[20px] py-[9px] w-[90%]'>
                    <p className='md:text-[22px] text-[15px] font-bold text-Textdarkcolor w-[90%]'>Help & Support</p>
                </div>
                <div className=' w-[90%] lg:px-2 px-2'>
                    <form className='lg:w-[90%] w-[100%]' onSubmit={formik.handleSubmit}>
                        <img src={HelpSupport} className='w-[492px] h-[240px] bg-red-300 mx-auto' />
                        <div className='w-[100%] flex flex-col justify-start  '>
                            <Inputfield
                                labelstyle="flex md:text-[18px] text-[14px]  mt-[18px] text-Textdarkcolor  font-regular"
                                inputStyle="mt-[8px] md:text-[18px] text-[14px] bg-[#fff]  w-full outline-0  p-3 rounded-[8px] shadow-[0px_8px_32px_#00000014] "
                                Labelname="Email" type="email" placeholder="Enter your Email"
                                name="email"
                                htmlFor="email"
                                id="email"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.email}
                                errors={formik.errors.email}
                                touched={formik.touched.email}
                                disabled={true}
                                isEditing={true}
                            />
                            <Inputfield
                                labelstyle="flex md:text-[18px] text-[14px]  mt-[12px] text-Textdarkcolor   font-regular"
                                inputStyle="mt-[8px] md:text-[18px] text-[14px] bg-[#fff] w-full outline-0 p-3 rounded-[8px] shadow-[0px_8px_32px_#00000014] "
                                Labelname="Contact Number" type="numeric" placeholder="Enter your Contact Number"
                                name="contact_no"
                                htmlFor="contact_no"
                                id="contact_no"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.contact_no}
                                errors={formik.errors.contact_no}
                                touched={formik.touched.contact_no}
                                disabled={true}
                                isEditing={true}
                            />
                            <div>
                                <label className='flex md:text-[18px] text-[14px]  mt-[12px] text-Textdarkcolor font-regular'>Description</label>
                                <textarea id="description" rows="3" className="mt-[8px] md:text-[18px] text-[12px] p-3 w-full  bg-[#fff]  outline-0 rounded-[8px] shadow-[0px_8px_32px_#00000014]" placeholder="Write your thoughts here..."
                                    name="description"
                                    htmlFor="description"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.description}
                                    errors={formik.errors.description}
                                    touched={formik.touched.description}
                                ></textarea>
                                {formik.touched.description && formik.errors.description ? (
                                    <div className="text-red-500">{formik.errors.description}</div>
                                ) : null}
                            </div>
                        </div>
                        <div className='flex justify-end align-middle mt-2'>
                            <LoadingButton
                                isLoading={isProcessing}
                                type="submit" // Set type to "submit" for the button
                            >
                                Continue
                            </LoadingButton>
                        </div>
                    </form>
                </div>
                <ToastContainer limit={1} />
            </div >
        );
    }
};

export default Maincard;




