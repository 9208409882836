import React from 'react';
import { del, post } from '../../utils/axiosInstance';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from 'react-redux';
import { setLogout } from '../../state';
import { useNavigate } from 'react-router-dom';

const DeleteAccount = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        const Token = localStorage.getItem('token');
        del('/user/delete-account', {}, Token)
            .then((res) => {
                console.log('====================================');
                console.log(res);
                console.log('====================================');
                dispatch(setLogout())
                localStorage.clear();
                navigate('/');
            })
            .catch((err) => {
                console.log('====================================');
                console.log(err);
                console.log('====================================');
            })
    }
    return (
        <div className='bg-[#FFFFFF] rounded-[10px] flex flex-col w-[100%] lg:h-[670px] md:h-[500px] h-[360px]'>
            <p className=' md:mt-[27px] mt-[15px] md:text-[22px] text-[16px] font-bold text-red-600 w-[90%]'>Delete Account</p>
            <div className='flex justify-center items-center bg-white h-full'>
                <div class="p-4 md:p-5 text-center ">
                    <svg class="mx-auto mb-4 text-gray-400 w-20 h-20 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                    </svg>
                    <h2 class="mb-5 text-lg font-bold text-black dark:text-black">Are you sure you want to delete your account?</h2>
                    <div className=''>
                        <button data-modal-hide="popup-modal" type="button" class="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center" onClick={() => handleSubmit()}>
                            Yes, I'm sure
                        </button>
                    </div>
                </div>
            </div>
            <ToastContainer limit={1} />
        </div >
    );
};

export default DeleteAccount;