import React, { useEffect, useState, useRef, useCallback } from "react";
import { get } from "../../utils/axiosInstance";
import Spinner from "../Spinner";
import Search from "../../assets/search.svg";
import Location from "../../assets/location.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  setAddress,
  setBookedDates,
  setCart,
  setSelectedVendor,
} from "../../state";
import Toast from "../Toast";
import { ToastContainer } from "react-toastify";

const Searchaddress = ({
  switchComponent,
  goToNextStep,
  goToPreviousStep,
  removeBackButton,
}) => {
  const dispatch = useDispatch();
  const Token = useSelector((state) => state.token);
  const selectedVendor = useSelector((state) => state.selectedVendor);
  const user_selected_vendor_id = useSelector(
    (state) => state.user_selected_vendor_id
  );
  const address = useSelector((state) => state.address);
  const [selectedLocation, setSelectedLocation] = useState();
  const [filteredAddresses, setFilteredAddresses] = useState([]);
  const [loader, setLoader] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [searchfield, setSearchField] = useState("");
  const [activeAddress, setActiveAddress] = useState(null);
  const [cartAvailable, setCartAvailable] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMorePages, setHasMorePages] = useState(true);
  const user = useSelector((state) => state.user);
  const vendorId = selectedVendor?.id ?? user_selected_vendor_id;

  const loadMoreData = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  useEffect(() => {
    setLoader(true);
    const params = {
      page: currentPage,
      limit: 200,
    };

    get(`/location/location/3/${vendorId}?`, params, {}, Token)
      .then((res) => {
        setLoader(false);
        const response = res.response.data.addresses;
        setSelectedLocation(response);
        setFilteredAddresses(response);
        setHasMorePages(res.response.next_page_url !== null);
      })
      .catch((err) => {
        setLoader(false);
        // console.log("Location Error:", err);
      });
  }, [currentPage, user.user_selected_vendor_id, Token]);

  const getBookings = async (locationID, cbSuccess, cdFailure) => {
    setLoader(true);
    // let cart = false;
    await get(`/cart/assigned-cart/${locationID}`, {}, {}, Token)
      .then((res) => {
        if ("response" in res) {
          setLoader(false);
          dispatch(setBookedDates(res.response.data.booked_dates));
          dispatch(setCart(res.response.data.assigned_cart));
          // return true;
          cbSuccess();

          // cart = true;
        } else {
          cdFailure();
          // return false; // Return false indicating cart update failed
        }
      })
      .catch((err) => {
        setLoader(false);
        dispatch(setCart({}));
        cdFailure();
        // return false;
      });
    // return true;
  };

  // const handleClick = async (clickedAddress) => {
  //   dispatch(setBookedDates([]));
  //   getBookings(clickedAddress.id);
  //   dispatch(setAddress(clickedAddress));
  //   setSelectedAddress(clickedAddress);
  //   switchComponent("Booking");
  //   goToNextStep();
  // };

  const handleClick = async (clickedAddress) => {
    dispatch(setBookedDates([]));
    console.log("clickedAddress", clickedAddress);
    await getBookings(
      clickedAddress.id,
      () => {
        dispatch(setAddress(clickedAddress));
        setSelectedAddress(clickedAddress);
        switchComponent("Booking");
        goToNextStep();
      },
      () => {
        dispatch(setAddress(clickedAddress));
        setSelectedAddress(clickedAddress);
        switchComponent("Booking");
        goToNextStep();
      }
    );
    // dispatch(setAddress(clickedAddress));
    // setSelectedAddress(clickedAddress);
    // console.log(cartUpdated,"cartUpdated");
    // if (cartUpdated) {
    //   switchComponent("Booking");
    //   goToNextStep();
    // }
    // else {

    // }
  };

  const filterAddresses = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchField(query);
    const filtered = selectedLocation.filter((address) => {
      const fullAddress = address.full_address.toLowerCase();
      return fullAddress.includes(query.toLowerCase());
    });
    localStorage.setItem("query", query);
    setFilteredAddresses(filtered);
  };

  if (loader) {
    return (
      <div className="flex justify-center items-center w-full lg:h-[670px] md:h-[670px] h-[650px]">
        <Spinner className="spinner" isLoading={loader} />
      </div>
    );
  } else {
    return (
      <div className="bg-[#FFFFFF] rounded-[10px] flex flex-col lg:justify-between justify-between items-center w-[100%] md:h-[670px] h-[87vh]">
        <p className="md:my-[27px] my-[20px] md:text-[22px] text-[18px] font-bold text-Textdarkcolor  w-[90%]">
          {/* Select and Add Location */}
          Start reservation: Select or type address
        </p>
        <div className="lg:w-[60%] w-[95%] md:p-4 py-2 px-4 custom-scrollbar h-full justify-between ">
          <form>
            <label className="mt-[12px] md:text-[16px] text-[14px] font-semibold text-Textdarkcolor ">
              Search Address by Name
            </label>
            <div className="relative w-full mt-[12px]">
              <img
                className="absolute md:top-5 top-[13px] left-3 md:w-4 md:h-4 h-3 w-3"
                src={Search}
              />
              <input
                type="search"
                id="search-input"
                className="z-0 block w-full outline-0 md:text-[16px] text-[13px]  md:p-4 p-2 md:pl-10 pl-8 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50"
                placeholder="Search by Address"
                enterKeyHint="search"
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    e.stopPropagation();
                    localStorage.setItem("selectedAddress", null);
                    switchComponent("Searchmap");
                    return false;
                  }
                }}
                value={searchfield}
                onChange={filterAddresses}
              />
            </div>

            <div className="pt-[12px]">
              {filteredAddresses.length === 0 ? (
                <div
                  className="flex text-[black] border-gray-300 rounded-lg bg-gray-50 md:p-4 p-2 hover:bg-[#FFFFFF] hover:border-none hover:shadow-[0_2px_25px_-20px_#000000] cursor-pointer"
                  onClick={() => {
                    localStorage.setItem("selectedAddress", null);
                    switchComponent("Searchmap");
                  }}
                >
                  <img
                    className="w-[25px] h-[25px]"
                    src={Location}
                    alt="No matching addresses"
                  />
                  <p className="pl-4">Add New Address</p>
                </div>
              ) : (
                filteredAddresses.map((address, index) => (
                  <li
                    key={index}
                    // ref={index === filteredAddresses.length - 1 ? lastAddressRef : null}
                    className={`block mt-4 cursor-pointer w-full md:p-4 p-2 md:pl-10 pl-5 text-sm border ${activeAddress && activeAddress.id === address.id
                      ? " text-[black] border-gray-300 rounded-lg bg-gray-200"
                      : "bg-[#F9F9FC] hover:bg-[#FFFFFF] hover:border-none hover:shadow-[0_2px_25px_-20px_#000000] text-[black] border border-gray-300 rounded-lg"
                      }`}
                    onClick={() => {
                      handleClick(address);
                    }}
                  >
                    {address.full_address}
                  </li>
                ))
              )}
            </div>
          </form>
        </div>

        <div className="flex justify-between w-[90%] md:my-10 my-5">
          {!removeBackButton && (
            <button
              className="bg-Btncolor text-Textdarkcolor lg:text-[16px]  rounded-[10px] lg:w-[145px] lg:h-[50px] 
                     md:w-[130px] md:h-[40px] w-[120px] h-[40px]"
              onClick={() => {
                goToPreviousStep();
                dispatch(setSelectedVendor(null));
                switchComponent("Vendors");
              }}
            >
              Back
            </button>
          )}
        </div>
        <ToastContainer limit={1} />
      </div>
    );
  }
};

export default Searchaddress;
