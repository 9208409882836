
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

const Settingsidecard = ({ activeComponent, setActiveComponent }) => {

    const user = useSelector((state) => state.user);

    const menuItems = [
        { heading: 'Personal Information', label: 'My Profile', key: 'A' },
        { label: 'Change Password', key: 'B' },
        { heading: 'About App', label: 'Privacy Policy', key: 'C' },
        { label: 'Terms and Conditions', key: 'D' },
        { label: 'Waiver of Liability', key: 'F' },
    ];

    // Include 'Choose Preferred Vendor' menu item conditionally based on user type
    if (user.user_type === 'guest') {
        menuItems.push({ label: 'Choose Preferred Vendor', key: 'E' });
        menuItems.push({ label: 'Help & Support', key: 'Help&Support' });
        menuItems.push({ label: 'Delete Account', key: 'G' });
    } else {
        menuItems.push({ label: 'Help & Support', key: 'Help&Support' });
        menuItems.push({ label: 'Delete Account', key: 'G' });
    }



    return (
        <div className='h-[100%] '>
            <div className='lg:block hidden bg-[#FFFFFF] rounded-[10px] flex flex-col w-[80%] h-full '>
                <div className='p-4 border-b-[1px] border-[#EDF0F6]'>
                    <p className='text-[25px] font-semibold'>Settings</p>
                </div>
                <div className=''>
                    {menuItems.map(item => (
                        <div key={item.key}>
                            <p className={`${item.heading ? 'text-[18px] p-4 font-semibold' : null}`}>{item.heading} </p>
                            <p className={`text-[16px] p-4 cursor-pointer hover:bg-[#EDF0F6] border-b-[1px] border-[#EDF0F6] ${activeComponent === item.key ? 'bg-[#EDF0F6]' : ''}`} onClick={() => setActiveComponent(item.key)}>{item.label}</p>
                        </div>
                    ))}
                </div>
            </div>

            <div className='lg:hidden block bg-[#FFFFFF] rounded-[10px] flex flex-col justify-center lg:w-[80%] w-[100%]  items-center'>
                <div className='p-4 w-full border-b-[1px] border-[#EDF0F6]'>
                    <p className='flex justify-start lg:text-[25px] text-[16px] font-semibold'>Settings</p>
                </div>
                <p className=' flex-start md:w-[80%] text-center w-[95%] py-2 grid md:grid-cols-4 sm:grid-cols-3 grid-cols-2 items-center md:gap-2'>
                    {menuItems.map(item => (
                        <span key={item.key} className={`lg:text-[16px] text-[12px] p-4 cursor-pointer rounded-full hover:bg-[#EDF0F6] ${activeComponent === item.key ? 'bg-[#EDF0F6]' : ''}`} onClick={() => setActiveComponent(item.key)}>{item.label}</span>
                    ))}
                </p>
            </div>
        </div>
    );
};

export default Settingsidecard;
