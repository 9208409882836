
import React, { useState } from 'react';
// import Vehicle from '../../assets/Vehicle.svg'
// import Inputfield from '../../components/Inputfiled';
import Sidenav from '../Sidenav';
import Card from './card';
import Avatar from '../../assets/avatar.svg';
import { Logout } from '../Modals/Logout';
import { useSelector } from 'react-redux';
import Maincard from './Maincard';

const HelpSupport = () => {

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const user = useSelector((state) => state.user);
    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    return (
        <div className="w-[100%] flex   flex-row">
            <Maincard />
        </div>
    );
};

export default HelpSupport;
