import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  token: null,
  user_type: null,
  selectedVendor: null,
  address: null,
  assigned_cart: null,
  extra_cart: [],
  vendorList: [],
  bookedDates: [],
  useJourneyVendor: null,
  user_agreement: false,
  app_vendor_id: null,
  user_selected_vendor_id: null,
  selectedValue: null,
  default_loader: false
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLogin: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.user_type = action.payload.user.user_type;
      state.app_vendor_id = action.payload.user.app_vendor_id;
      state.user_selected_vendor_id = action.payload.user.user_selected_vendor_id;
    },

    setLogout: (state) => {
      state.user = null;
      state.token = null;
      state.user_type = null;
      state.selectedVendor = null;
      state.address = null;
      state.vendorList = [];
      state.bookedDates = [];
      state.assigned_cart = null;
      state.extra_cart = [];
      state.useJourneyVendor = null;
      state.user_agreement = false
      state.app_vendor_id = null
    },
    setUserState: (state, action) => {
      state.user = action.payload;
    },
    setUseJourneyVendor: (state, action) => {
      state.useJourneyVendor = action.payload;
    },
    setDefaultLoader: (state, action) => {
      state.default_loader = action.payload;
    },
    setUseAgreement: (state, action) => {
      state.user_agreement = action.payload;
    },
    setAppVendorId: (state, action) => {
      state.app_vendor_id = action.payload;
    },
    setSelectedVendor: (state, action) => {
      state.selectedVendor = action.payload;
    },
    setAddress: (state, action) => {
      state.address = action.payload;
    },
    setCart: (state, action) => {
      state.assigned_cart = action.payload;
    },
    setExtraCart: (state, action) => {
      state.extra_cart = action.payload;
    },
    setBookedDates: (state, action) => {
      state.bookedDates = action.payload;
    },

    setVendorList: (state, action) => {
      state.vendorList = action.payload.vendorList;
    },
    setSelectedValue: (state, action) => {
      state.selectedValue = action.payload;
    },
  },
});


export const {
  setLogin,
  setLogout,
  SelectedVendorId,
  setSelectedVendor,
  setAddress,
  setCart,
  setBookedDates,
  setVendorList,
  setExtraCart,
  setUseJourneyVendor,
  setUseAgreement,
  setUserState,
  setSelectedValue,
  setDefaultLoader
} = authSlice.actions;
export default authSlice.reducer;
